'use client';
import { ResponseWidget } from '@/src/interfaces/data-contracts';
import { useContext, useMemo, useState } from 'react';
import {
  AccordionChevron,
  AccordionChevronActive,
  AccordionMinus,
  AccordionPlus,
  ChevronDown,
} from '../../ui/icons/icons';
import { DeviceContext } from '@/src/store/contexts/device-context';

type Props = {
  faqData: ResponseWidget;
};

export const FaqSectionV2: React.FC<Props> = ({ faqData }) => {
  const { isMobile } = useContext(DeviceContext);
  const [activeTab, setActiveTab] = useState(-1);
  const [showMore, setShowMore] = useState(false);

  const faqList = useMemo(() => {
    const details = faqData.details || [];
    return showMore ? details : details.slice(0, 4);
  }, [showMore]);

  if (faqList.length === 0) return <></>;

  return (
    <div>
      <h2 className="mb-8 text-[20px] font-semibold leading-[28px] -tracking-[0.48px] text-[#252525] md:text-[24px] md:leading-[32px] ">
        Sık Sorulan Sorular
      </h2>
      <ul className="rounded-[12px] border-[1px] border-[#D2DDE7]">
        {faqList.map((item, i) => {
          const isActive = activeTab === i;
          return (
            <li key={item.id} className="border-b-[1px] border-[#D2DDE7] last:border-b-[0px]">
              <h3
                role="none"
                className={`flex cursor-pointer justify-between px-4 pb-4 pt-5 text-[16px] font-semibold leading-[24px] md:p-8 ${isActive ? 'text-[#06B559]' : ''}`}
                onClick={() => setActiveTab((prev) => (prev === i ? -1 : i))}
              >
                <span className="mr-4">{item.title}</span>
                {isMobile ? (
                  <span>
                    {isActive ? (
                      <AccordionChevronActive height={24} width={24} />
                    ) : (
                      <AccordionChevron height={24} width={24} />
                    )}
                  </span>
                ) : (
                  <span>
                    {isActive ? <AccordionMinus height={24} width={24} /> : <AccordionPlus height={24} width={24} />}
                  </span>
                )}
              </h3>
              <div
                className={`${isActive ? 'block' : 'hidden'} accordion_content mr-10 px-4 pb-5 text-[14px] leading-[20px] md:mr-0 md:px-8 md:pb-8 md:text-[16px] md:leading-[24px]`}
                dangerouslySetInnerHTML={{ __html: item.description ?? '' }}
              />
            </li>
          );
        })}
      </ul>
      {(showMore || (faqData.details && faqList.length < faqData.details.length)) && (
        <button
          className="m-auto mb-[40px] mt-[32px] flex items-center"
          onClick={() => {
            setShowMore((prev) => !prev);
          }}
        >
          <span className="mr-[6px] py-[10px] text-[14px] leading-[20px] text-[#06B559]">
            Daha {showMore ? 'az' : 'fazla'} görüntüle
          </span>
          <ChevronDown height={20} width={20} className={`${showMore ? 'rotate-[180deg]' : ''}`} />
        </button>
      )}
    </div>
  );
};
