import classNames from 'classnames';
import { MouseEventHandler } from 'react';

type tagOptions = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';

interface IHeading {
  children: React.ReactNode;
  className?: string;
  cls?: string;
  tagType: tagOptions;
  //sizeType: keyof typeof sizeOptions;
  bolded?: boolean;
  onclick?: MouseEventHandler<HTMLHeadingElement>;
}

const Heading: React.FC<IHeading> = ({ children, cls, tagType, bolded, onclick, className, ...rest }) => {
  const settedClasses = classNames(
    //sizeOptions[iheading.sizeType],
    className,
    cls,
    bolded === true ? 'font-bold' : ''
  );

  const TagType = tagType;

  return (
    <TagType onClick={onclick} className={settedClasses} {...rest}>
      {children}
    </TagType>
  );
};

export default Heading;
