import { ResponseWidgetDetail } from '@/src/interfaces/data-contracts';
import { AccordionTabItem } from './accordion-tab-item';

type Props = {
  dataToAccordion: ResponseWidgetDetail[];
  accordionTabItemClasses: string;
  accordionTitleClasses: string;
  accordionTextClasses: string;
  accordionVisibleTab: number | null;
  accordionTabIcon: boolean;
  accordionContentBtnVisible: boolean;
  accordionContentBtnText: string;
  accordionContentBtnClasses: string;
  btnUrl: string;
  accordionHideSomeContent: boolean;
  accordionVisibleContentNumber: number;
};

export const AccordionTab: React.FC<Props> = (props) => {
  const originalFaqArray = [...props.dataToAccordion];
  const accordionTabItem = (
    incomingKey: number,
    incomingSendKey: number,
    incomingTitle: string,
    incomingDescription: string,
    incomingLink: string,
    incomingLinkText: string
  ) => {
    return (
      <AccordionTabItem
        key={incomingKey}
        sendKey={incomingSendKey}
        title={incomingTitle}
        description={incomingDescription}
        accordionTabItemClasses={props.accordionTabItemClasses}
        accordionTitleClasses={props.accordionTitleClasses}
        accordionTextClasses={props.accordionTextClasses}
        accordionVisibleTab={props.accordionVisibleTab}
        accordionTabIcon={props.accordionTabIcon}
        accordionContentBtnVisible={props.accordionContentBtnVisible}
        accordionContentBtnText={incomingLinkText}
        accordionContentBtnClasses={props.accordionContentBtnClasses}
        link={incomingLink}
      />
    );
  };

  return (
    <ul data-testid="accordionTabList">
      {originalFaqArray
        .toSorted((a, b) => a.displayOrder! - b.displayOrder!)
        .map((item, index: number) => {
          return (
            <li
              key={item.id}
              className={index >= props.accordionVisibleContentNumber && props.accordionHideSomeContent ? 'hidden' : ''}
              data-testid="accordionTab"
            >
              {accordionTabItem(
                index,
                index,
                item.title ?? '',
                item.description ?? '',
                item.link ?? '',
                item.linkText ?? ''
              )}
            </li>
          );
        })}
    </ul>
  );
};
